import React, { useEffect, useRef, useState } from "react";
import "./certificatePage.css";
import { saveAs } from "file-saver";
import templateImages from "../utils/images/template";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import logo from "../assets/images/recognify-logo.png";
import StudentDataInfo from "../studentInfoPopUp/studentInfoData";
import DownloadPopUp from "../downlaod/downloadPopup";
import JSZip from "jszip";



export default function Certificate(){
  const [selectedTemplate, setSelectedTemplate] = useState(templateImages[4]);
  const [fontFamily ,setFontFamily]= useState('Normal')
  const [topDescription, setTopDescription] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [certifiedBy, setCertifiedBy] = useState("");
  const [logoImage, setLogoImage] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [openDownload, setIsOpenDownload] = useState(false);
  const [image, setImage] = useState(null);
  const [names, setNames] = useState([]);
  const [openStudent, setOpenStudentPopUp] = useState(false);
  const scrollRef = useRef();
  const [openSettingTopDescription ,setOpenTopDescription] = useState(false);

  const downloadAsPDF = async () => {
    const zip = new JSZip();
    if (names.length === 1) {
      const recipientName = names[0];
      document.querySelector(".name-of-certification").innerText =
        recipientName.name;
      const certificateElement = document.querySelector(
         ".preview-image-container"
      );
  
      try {
        const scale = 4;
        const canvas = await html2canvas(certificateElement, {
          useCORS: true,
          scale: scale,
        });
  
        const image = canvas.toDataURL("image/png");
        const pdf = new jsPDF("landscape", "mm", [
          canvas.width / scale,
          canvas.height / scale,
        ]);
        pdf.addImage(image, "PNG", 0, 0, canvas.width / scale, canvas.height / scale);
        pdf.save(`${recipientName.name} certificate.pdf`);
  
        toast.success("Certificate downloaded successfully as a PDF.");
      } catch (error) {
        console.error("Error generating PDF:", error);
        toast.error("An error occurred while generating the PDF.");
      }
    } else {
      const folder = zip.folder("Certificates");
      for (const recipientName of names) {
        document.querySelector(".name-of-certification").innerText =
          recipientName.name;
        const certificateElement = document.querySelector(
          ".preview-image-container"
        );
  
        try {
          const scale = 4;
          const canvas = await html2canvas(certificateElement, {
            useCORS: true,
            scale: scale,
          });
  
          const image = canvas.toDataURL("image/png");
          const pdf = new jsPDF("landscape", "mm", [
            canvas.width / scale,
            canvas.height / scale,
          ]);
          pdf.addImage(image, "PNG", 0, 0, canvas.width / scale, canvas.height / scale);
  
          const pdfBlob = pdf.output("blob");
          const fileName = `${recipientName.name} certificate.pdf`;
          folder.file(fileName, pdfBlob);
        } catch (error) {
          console.error(
            `Error generating PDF for ${recipientName.name}:`,
            error
          );
          toast.error(
            `An error occurred while generating the certificate for ${recipientName.name}.`
          );
        }
      }
      zip
        .generateAsync({ type: "blob" })
        .then((content) => {
          saveAs(content, "Certificates.zip");
          toast.success("Certificates downloaded successfully as a ZIP file.");
        })
        .catch((error) => {
          console.error("Error generating ZIP file:", error);
          toast.error("An error occurred while generating the ZIP file.");
        });
    }
  };


  function changeSettingTopDescription(){
    setOpenTopDescription(!openSettingTopDescription)
  }
  

  function addName(value) {
    if (value.trim() === "") {
      return;
    }
    setNames((prev) => [...prev, { name: value }]);
  }

  useEffect(() => {
    setRecipientName("");
  }, [names]);

  const downloadAsImage = async () => {
    const zip = new JSZip();
  
    if (names.length === 1) {
      const recipientName = names[0];
      document.querySelector(".name-of-certification").innerText =
        recipientName.name;
      const certificateElement = document.querySelector(
        ".preview-image-container"
      );
  
      try {
        const canvas = await html2canvas(certificateElement, {
          useCORS: true,
          scale: 4,
        });
        const imageData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imageData;
        link.download = `${recipientName.name} certificate.png`;
        link.click();
  
        toast.success("Certificate downloaded successfully as an image.");
      } catch (error) {
        console.error("Error generating image:", error);
        toast.error("An error occurred while generating the image.");
      }
    } else {
      const folder = zip.folder("Certificates");
      for (const recipientName of names) {
        document.querySelector(".name-of-certification").innerText =
          recipientName.name;
        const certificateElement = document.querySelector(
          ".preview-image-container"
        );
  
        try {
          const canvas = await html2canvas(certificateElement, {
            useCORS: true,
            scale: 4,
          });
          const imageData = canvas.toDataURL("image/png");
  
          const fileName = `${recipientName.name} certificate.png`;
          folder.file(fileName, imageData.split(",")[1], { base64: true });
        } catch (error) {
          console.error(
            `Error generating image for ${recipientName.name}:`,
            error
          );
          toast.error(
            `An error occurred while generating the certificate for ${recipientName.name}.`
          );
        }
      }
  
      zip
        .generateAsync({ type: "blob" })
        .then((content) => {
          saveAs(content, "Certificates.zip");
          toast.success("Certificates downloaded successfully as a ZIP file.");
        })
        .catch((error) => {
          console.error("Error generating ZIP file:", error);
          toast.error("An error occurred while generating the ZIP file.");
        });
    }
  };
  

  const handlePress = async () => {
    try {

      if(names.length<=0){
        toast.error("Please add name first","error5");
        return;
      }
      const certificateElement = document.querySelector(
        ".preview-image-container"
      );
      const canvas = await html2canvas(certificateElement, {
        useCORS: true,
        scale: 4,
      });
      const imageDataUrl = canvas.toDataURL("image/png");

      setImage(imageDataUrl);
      openModal();
    } catch (error) {
      toast.error("Failed to download certificate.");
    }
  };

  const openModal = () => {
    setIsOpenDownload(!openDownload);
  };

  const handleTemplateClick = (template) => {
    setSelectedTemplate(template);
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
      if (file.size > maxSize) {
        toast.error("Please upload file less than 10 mb.");
        return;
      }

      setLogoImage(URL.createObjectURL(file));
    }
  };

  const handleSignatureUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSize = 10 * 1024 * 1024;
      if (file.size > maxSize) {
        alert("File size exceeds 10 MB. Please upload a smaller file.");
        return;
      }

      setSignatureImage(URL.createObjectURL(file));
    }
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -200, // Adjust scroll step as needed
        behavior: "smooth", // Smooth animation
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  function deleteName(index) {
    setNames((prevItems) => prevItems.filter((_, i) => i !== index));
  }

  function openStudentPopUp() {
    setOpenStudentPopUp(!openStudent);
  }

  return (
    <div className="certificate-main-container">
      <div className="main-header-certificate-manager">
        <img className="logo-recognify" src={logo} alt="" />
      </div>
      <div className="sub-container-cm-manager">
        <div className="certificate-preview-container">
          <div className="main-preview-of-certificate">
            <div className="preview-image-container">
              <div className="certificate-data-info">
                <div className="organization-logo">
                  <img className="org-logo" src={logoImage} alt="" />
                </div>
                <div className={fontFamily==='Normal'? "description-line-first-normal" : "description-line-first-cursive"}>{topDescription}</div>
                <div draggable="true" className="name-of-certification">{recipientName!=="" ?recipientName : (names && names.length>0)?names[names.length-1].name : "Applicant name"}</div>
                <div draggable="true" className="description-line-second">{description}</div>

                <div className="issuer-info-container">
                  <div className="date-info">
                    {date && moment(date).format("DD MMM,YYYY")}
                  </div>
                  <div className="issuer-name">
                    <div className="sign-of-issuer">
                      <img draggable="true" className="sign-icon" src={signatureImage} alt="" />
                    </div>
                    <div draggable="true" className="name">{certifiedBy}</div>
                  </div>
                </div>
              </div>
              <img
                src={selectedTemplate}
                alt="certificate-preview"
                className="certificate-preview"
              />
            </div>
            <div className="all-templates">
              <div onClick={scrollLeft} className="left-shift">
                <i class="ri-arrow-left-line"></i>
              </div>
              <div onClick={scrollRight} className="right-shift">
                <i class="ri-arrow-right-line"></i>
              </div>
              <div ref={scrollRef} className="template-mapped">
                {templateImages.map((template, index) => (
                  <div
                    key={index}
                    className="template-box"
                    onClick={() => handleTemplateClick(template)}
                  >
                  {selectedTemplate==template ?   <img
                      src={template}
                      className="image-templates-selected"
                      alt={`template ${index + 1}`}
                    />:  <img
                    src={template}
                    className="image-templates"
                    alt={`template ${index + 1}`}
                  />}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="input-for-certificate">
            <div className="input-for-certificate-sub-container">
              <div className="main-heading-certificate">
                Generate certificate
              </div>
              <div className="certificate-input-container">
                <div className="input-data-label-container">
                  <label className="label-certificate-inputs">Awarded to</label>
                  <div className="add-student-container-data">
                    <div className="add-student-name">
                      <div
                        onClick={() => addName(recipientName)}
                        className="add-container"
                      >
                        + Add
                      </div>
                      {names && names.length ? (
                        <div
                          onClick={() => openStudentPopUp()}
                          className="count-of-added"
                        >
                          {names.length}
                        </div>
                      ) : null}
                    </div>
                    <input
                      value={recipientName}
                      onChange={(e) => setRecipientName(e.target.value)}
                      className="input-of-certificates-name"
                      type="text"
                    />
                  </div>
                </div>

                <div className="input-data-label-container">
                  <label className="label-certificate-inputs">
                    Description line 1
                  </label>
                {openSettingTopDescription &&   <div className="setting-options">
                   <div>
                   <label className="setting-top-description" htmlFor="">Change font </label>
                    <select onChange={(e)=>setFontFamily(e.target.value)} name="" id="" className="selection-of-font-family">
                      <option value="Normal">Normal</option>
                      <option value="Cursive">Cursive</option>
                    </select>
                   </div>

                   <div>
                   <label className="setting-top-description" htmlFor="">Font size</label>
                    <select name="" id="" className="selection-of-font-family">
                      <option value="">Normal</option>
                      <option value="">Medium</option>
                      <option value="">Big</option>
                    </select>
                   </div>
                  </div>}
                  {/* <div onClick={()=>changeSettingTopDescription()} className="setting-to-change-font">
                  <i class="ri-equalizer-line"></i>
                  </div> */}
                  <input
                    value={topDescription}
                    onChange={(e) => setTopDescription(e.target.value)}
                    className="input-of-certificates"
                    type="text"
                  />
                </div>

                <div className="input-data-label-container">
                  <label className="label-certificate-inputs">
                    Description line 2
                  </label>
                  <input
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="input-of-certificates"
                    type="text"
                  />
                </div>

                <div className="issuer-info">
                  <div className="input-data-label-container">
                    <label className="label-certificate-inputs">
                      Issuer name
                    </label>
                    <input
                      value={certifiedBy}
                      onChange={(e) => setCertifiedBy(e.target.value)}
                      className="input-of-certificates"
                      type="text"
                    />
                  </div>
                  <div className="input-data-label-container">
                    <label className="label-certificate-inputs">
                      Date of issue
                    </label>
                    <input
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      className="input-of-certificates"
                      type="date"
                    />
                  </div>
                  <div className="input-data-label-container">
                    <label className="label-certificate-inputs">
                      Issuer sign
                    </label>
                    <label
                      htmlFor="file-upload-signature"
                      className="input-of-certificates"
                    ></label>
                    <input
                      type="file"
                      id="file-upload-signature"
                      style={{ display: "none" }}
                      onChange={handleSignatureUpload}
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                  <div className="input-data-label-container">
                    <label className="label-certificate-inputs">
                      Organization logo
                    </label>
                    <label
                      htmlFor="file-upload-logo"
                      className="input-of-certificates"
                    ></label>
                    <input
                      type="file"
                      id="file-upload-logo"
                      style={{ display: "none" }}
                      onChange={handleLogoUpload}
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                </div>

                <div className="generate-button-container">
                  <button
                    onClick={() => handlePress()}
                    className="generate-button"
                  >
                    Generate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openStudent && (
        <StudentDataInfo
          deleteName={deleteName}
          studentName={names}
          closeStudent={openStudentPopUp}
        />
      )}

      {openDownload && (
        <DownloadPopUp
          certificate={image}
          studentLength={names.length}
          downloadAsImage={downloadAsImage}
          downloadAsPDF={downloadAsPDF}
          closeModal={openModal}
        />
      )}

     
    </div>
  );
};

