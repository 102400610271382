import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './component/loginPage/loginPage';
import SignUp from './component/signUp/signUp';
import Certificate from './component/certificate/certificatePage';
import './App.css';
import { AppRoutes } from './routes/AppRoutes';
import Settings from './component/settings/settings';
import routes from './component/utils/App.json'
import ForgotPassword from './component/forgotPassword/forgotPassword';
import LayoutComponent from './component/AppLayout/appLayout';
import UserInfo from './component/store/userInfo';

function App() {
  return (
    <HashRouter>
      <AppRoutes />
       
      <UserInfo>
      <Routes>
        {/* <Route path={routes.main} element={<Certificate />} /> */}
        {/* <Route path={routes.signin} element={<LoginPage />} />
        <Route path={routes.signup} element={<SignUp />} />
        <Route path={routes.forgotPassword} element={<ForgotPassword />} /> */}

        <Route element={<LayoutComponent />}>
          <Route path={routes.main} element={<Certificate />} />
          {/* <Route path={routes.settings} element={<Settings />} /> */}
        </Route>

      </Routes>
      </UserInfo>
 
    </HashRouter>
  );
}
export default App;
