import React, { useContext, useEffect, useState } from 'react';
import { NewNavbar } from '../newNavbar/newNavbar';
import "./applayout.css"
import { Outlet } from 'react-router-dom';
import { UserInfoContext } from '../store/userInfo';
function LayoutComponent() {
  const [schoolInfo, setSchoolInfo] = useState();
  const {setUserInfo } = useContext(UserInfoContext);


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
    const encodedData = queryParams.get('data');
    if (encodedData) {
      const decodedData = JSON.parse(atob(encodedData));
      console.log(JSON.parse(decodedData.schoolAllInfo));
      setUserInfo(JSON.parse(decodedData.schoolAllInfo))
      setSchoolInfo(JSON.parse(decodedData.schoolAllInfo));
    }

  }, [setUserInfo]);

  const [openNavigationBar, setOpenNavigationBar] = useState(true);
  function changeNavigationBarState() {
    setOpenNavigationBar(!openNavigationBar);
  }
  return (
    <div className={openNavigationBar ? 'app-layout-application' : 'app-layout-application-close'}>
      {/* <div className='navigation'>
        <div onClick={() => changeNavigationBarState()} className="close-arrow-navigation">
          {openNavigationBar ? <i class="ri-arrow-drop-left-line"></i> : <i class="ri-arrow-drop-right-line"></i>}
        </div>
        <NewNavbar schoolInfo={schoolInfo} navigationState={openNavigationBar} />
      </div> */}
      <Outlet schoolInfo={schoolInfo} />
    </div>
  );
}
export default LayoutComponent;