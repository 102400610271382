import React, { createContext, useState } from 'react';

export const UserInfoContext = createContext();

const UserInfo = ({ children }) => {
  const [userInfo, setUserInfo] = useState();

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};

export default UserInfo;