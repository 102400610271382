import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import './studentInfoData.css'

export default function StudentDataInfo(props) {
    const [show, setShow] = useState(false);
    const students = props.studentName;

    useEffect(()=>{
        setShow(true)
    },[])

    function handleClose(){
        setShow(false);
        props.closeStudent();
    }

    function deleteName(index){
        props.deleteName(index);
    }
   
    return(
        <Modal show={show} onHide={handleClose} centered>
      
        <div className="student-info-main">
            <div className="student-info-sub-container">
            <table class="table-main-container">
  <thead>
    <tr>
      <th>Sr. No</th>
      <th>Student Name</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody class="table-body-student-info">
    {students && students.map((key,index)=>{
        return(
            <tr key={index}>
                <td>{index+1}</td>
                <td>{key.name}</td>
                <td><button onClick={()=>deleteName(index)} class="delete-btn">Delete</button></td>
            </tr>
        )
    })}

  </tbody>
</table>
            </div>

        </div>
      </Modal>
    )
}