import template1 from "../../assets/images/template1.png";
import template2 from "../../assets/images/template2.png";
import template3 from "../../assets/images/template3.png";
import template4 from "../../assets/images/template4.png";
import template5 from "../../assets/images/template5.png";
import template6 from "../../assets/images/template6.png";
import template7 from "../../assets/images/template7.png";
import template8 from "../../assets/images/template8.png";
import template9 from "../../assets/images/template9.png";
import template10 from "../../assets/images/template10.png";
import template11 from "../../assets/images/template11.png";
import template12 from "../../assets/images/template12.png";
import template13 from "../../assets/images/tempate_new.jpeg";
import template14 from "../../assets/images/template14.png";
import template15 from "../../assets/images/template15.png";

const templateImages = [
    template1,
    template2,
    template3,
    template4,
    template5,
    template6,
    template7,
    template8,
    template9,
    template10,
    template11,
    template12,
    template13,
    template14,
    template15,
];

export default templateImages;
