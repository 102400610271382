import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import constants from '../component/utils/config/config';

export const AppRoutes = () => {
    const nav = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        const token = localStorage.getItem(constants.localStorage.token);
        if (token && location.pathname !== constants.navigationLink.settings) {
            nav(constants.navigationLink.certificate);
        }
    }, [location.pathname, nav]);

    return null;
};
