import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./downloadPopUp.css"

export default function DownloadPopUp(props){
    const [show , setShow]=  useState(false);
    useEffect(()=>{
        setShow(true);
    },[])

    function handleClose(){
        setShow(false);
        props.closeModal();
    }
    
    return(
        <Modal size="large" className="modal-lg"  show={show} onHide={handleClose} centered>
            <div className="main-download-pop-up">
                    <div className="heading-hurray">
                      <div className="certificate-celebration-heading">
                      HURRAY !
                      </div>
                      <div className="certificate-celebration-sub-heading">
                        You have successfully generated certificate.
                      </div>
                    </div>

                    <div className="preview-container">
                        <div className="image-preview-container-pop-up">
                            <img className="pop-up-preview" src={props.certificate} alt="" />
                        </div>

                        <div className="certificate-info">
                                <div>Do you want to edit this file.</div>
                                <div className="edit">
                                    <button onClick={()=>handleClose()} className="edit-button">
                                    Edit file

                                    </button>
                                </div>
                                <div className="edit">
                                   <button className="download-image" onClick={()=>props.downloadAsImage()}>
                                   Download image {props.studentLength>1 ? 'zip file' : null}
                                   </button>
                                </div>

                                <div className="edit">
                                 <button className="download-pdf"  onClick={()=>props.downloadAsPDF()}>
                                    Download pdf {props.studentLength>1 ? 'zip file' : null}
                                 </button>
                                </div>
                        </div>
                    </div>
            </div>
        </Modal>
    )
}